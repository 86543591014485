.dao {
  max-width: var(--max-width);
  min-height: calc(100svh - 130px);
  margin-inline: auto;
  padding: 32px 16px;
}

.daoHead {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
}

.daoHeadInfo {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  width: 100%;
  flex: 1;
  padding: 16px 24px;
}

.daoHeadTitle {
  display: inline-grid;
  grid-template-columns: 1fr 40px;
  grid-template-rows: 1fr;
  gap: 16px;
  align-items: center;
  line-height: 1;
  margin: 16px 0;
}

.daoHeadTitle div {
  font-size: 2.5rem;
  font-weight: 700;
}

.daoHeadDescription {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 32px;
}

.daoHeadBtn {
  background-color: #22222210;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  position: relative;
  width: 40px;
  height: 40px;
}

.daoHeadSocials {
  display: flex;
  align-items: center;
  gap: 8px;
}

.daoHeadSocial {
  background-color: var(--white);
  border: 1px solid var(--dark-blue);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.daoContent {
  display: inline-flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.daoChartHead {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  width: 100%;
  padding: 16px 24px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
}

.daoChart {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  width: 100%;
  min-height: 348px;
  margin-bottom: 8px;
  padding: 24px;
}

.daoChartHead h3 {
  font-size: 28px;
  margin: 0;
}

.daoChart h4 {
  margin-bottom: 16px;
}

.daoChartDuo {
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-height: 348px;
}

.daoChartYear {
  cursor: pointer;
  position: relative;
}

.daoChartSelected {
  display: flex;
  align-items: center;
  gap: 8px;
}

.daoChartSelected span {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.25px;
}

.daoChartOptionsWrapper {
  position: absolute;
  top: 100%;
  right: -24px;
  width: calc(100% + 48px);
  z-index: 1;
  padding-top: 8px;
}

.daoChartOptions {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 8px;
}

.daoChartOption {
  border-radius: 4px;
  cursor: pointer;
  font-size: 22px;
  letter-spacing: -0.25px;
  width: 100%;
  padding: 4px 16px;
}

.daoChartOption:hover {
  background-color: var(--light-blue);
}

.daoChartNote {
  background-color: #001d6808;
  border-radius: 8px;
  color: var(--black);
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 8px;
  padding: 16px;
}

.daoChartNote strong {
  color: var(--black);
  font-weight: 700;
}

/* Markdown */
.markdown {
  max-width: 100%;
  color: var(--black);
  margin-bottom: 40px;
}

.markdown p {
  font-size: 18px;
  line-height: 1.6;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
  .daoHead {
    flex-direction: row;
  }

  .daoContent {
    max-width: 300px;
  }

  .daoChartDuo {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    gap: 32px;
  }
}