.wrapper {
  max-width: 798px;
  margin-inline: auto;
  min-height: calc(100svh - 80px);
  padding: 64px 16px;
}

.wrapper p {
  border-top: 1px solid #dcdcdc;
  color: var(--medium);
  padding: 24px 16px;
  text-align: center;
}