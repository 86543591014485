.copyText {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.copyTextIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}