.sankeyChart {
  position: relative;
}

.sankeyChartTooltip {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: none;
  font-size: 12px;
  width: auto;
  padding: 8px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.sankeyChartTooltip div {
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-template-rows: repeat(3, auto);
  line-height: 20px;
}

.sankeyChartTooltip span:first-child {
  color: var(--medium);
  font-weight: 500;
}

.sankeyChartTooltip span:last-child {
  color: var(--black);
  font-weight: 600;
  text-wrap: nowrap;
}

.sankeyChartError {
  background-color: rgba(255, 0, 0, 0.05);
  border-radius: 0px 4px 4px 0px;
  border-left: 2px solid red;
  color: red;
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 16px;
  word-wrap: break-word;
  overflow: hidden;
  padding: 8px;
  min-height: 180px;
}