.paginationContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.pagination button,
.pagination div {
  box-sizing: border-box;
  border-radius: 4px;
  word-wrap: none;
  white-space: nowrap;
  font-size: 0.9rem;
  font-weight: 600;
  min-height: 32px;
  line-height: 24px;
  padding: 4px 8px;
}

.pagination button {
  background-color: var(--white);
  border: 1px solid var(--dark-blue);
  color: var(--dark-blue);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination div,
.pagination button:disabled {
  background-color: #e9e9e980;
  border: 1px solid #e9e9e9;
  color: var(--medium);
  cursor: not-allowed;
}