.wrapper {
  max-width: var(--max-width);
  margin-inline: auto;
  padding: 64px 16px;
}

.wrapper h1 {
  font-size: clamp(1.4rem, 6svw, 2.4rem);
  margin-bottom: 8px;
}

.wrapper p {
  margin-bottom: 48px;
}