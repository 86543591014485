.donutChart {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.donutChartLegend {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  gap: 8px;
  line-height: 12px;
  text-transform: capitalize;
}

.donutChartLegendItem {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.donutChartLegendColor {
  border-radius: 50%;
  min-height: 14px;
  min-width: 14px;
}