.support {
  background-color: #001d6810;
  padding: 80px 16px;
  text-align: center;
}

.support hr {
  width: 120px;
  border: none;
  border-top: 1px solid #001d6820;
  margin: 40px auto;
}

.supportList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}