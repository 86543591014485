.tooltipCard {
  color: var(--dark);
  display: inline-block;
  min-width: 80px;
  max-width: 280px;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.tooltipCardTooltip {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(0%, -100%);
  background-color: var(--black);
  border-radius: 4px;
  width: 100%;
  padding: 8px 12px;
  z-index: 2;
}

.tooltipCardTooltip:before {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--black);
  border-radius: 2px;
}

.tooltipCardTooltip p {
  color: var(--white);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 !important;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  text-align: center;
}

.tooltipCardPulse {
  display: inline-block;
  margin: 0 8px;
}