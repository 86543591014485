.resultsCard {
  background-color: var(--white);
  border: var(--border);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 4px;
  padding: 16px;
  margin-bottom: 24px;
}

.resultsCardIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.resultsCard div {
  color: var(--medium);
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 24px;
  text-wrap: wrap;
}