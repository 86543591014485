.infoCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

.infoCard {
  position: relative;
}

.infoCard .infoCardTooltip {
  background-color: var(--white);
  border-radius: 8px;
  border: var(--border);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  visibility: hidden;
  top: 100%;
  right: 0;
  width: 180px;
  white-space: wrap;
  padding: 12px;
  text-align: left;
  z-index: 1;
}

.infoCardTooltip p {
  color: var(--medium);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  text-align: left;
}

.infoCard:hover .infoCardTooltip {
  visibility: visible;
}