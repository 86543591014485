.profile {
  max-width: var(--max-width);
  min-height: calc(100svh - 130px);
  margin-inline: auto;
  padding: 32px 16px;
}

.profileInfo {
  width: 100%;
  max-width: 698px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.profileName {
  font-size: 2.5rem;
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1;
  max-width: 460px;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileIdentities {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.profileAbout {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.profileAboutProfiles .profiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-template-rows: auto;
  gap: 24px;
}

.profileAboutDetails {
  background-color: #22222205;
  border: var(--border);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
}

.profileItem {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  line-height: 24px;
  margin-bottom: 8px;
}

.profileItemIcon {
  background-color: #22222205;
  border-radius: 50%;
  border: var(--border);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  min-height: 24px;
}

.profileItem span {
  color: var(--dark-blue);
  font-weight: 600;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileLocation {
  display: flex;
  align-items: center;
  gap: 8px;
}

.profileLocation p {
  max-width: 100%;
  white-space: nowrap;
  word-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 668px) {
  .profileAbout {
    display: grid;
    grid-template-columns: 5fr minmax(280px, 1fr);
    grid-template-rows: 1fr;
    align-items: flex-start;
    gap: 40px;
  }
}