.notificationCard,
.notificationCardNews {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid #dcdcdc;
  padding: 16px;
}

.notificationCard {
  background-color: var(--white);
}

.notificationCardContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.notificationCardImg {
  border-radius: 50%;
  width: 48px;
  height: 48px;
}

.notificationCardInfo {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  justify-content: space-between;
}

.notificationCardTime {
  color: var(--medium);
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

.notificationCardNewsThumbnail {
  border-radius: 4px;
  display: block;
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.notificationCardAsub {
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--black);
  font-weight: 600;
}

.notificationCardAmsg {
  font-size: 1.15rem;
  line-height: 1.2;
  color: var(--medium);
}

.notificationCardInfo div:first-child {
  display: none;
}

@media screen and (min-width: 468px) {

  .notificationCard,
  .notificationCardNews {
    background-color: transparent;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 16px;
    padding: 24px;
  }

  .notificationCard {
    background-color: transparent;
    grid-template-columns: 54px 1fr 90px;
  }

  .notificationCardNews {
    grid-template-columns: 54px 1fr 200px;
  }

  .notificationCardNewsThumbnail {
    width: 200px;
  }

  .notificationCardInfo div:first-child {
    display: block;
  }
}