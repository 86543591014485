.govBadge {
  display: flex;
  align-items: center;
  justify-content: center;
}

.govBadgeIcon {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 4px;
}